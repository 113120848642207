import React from 'react';
import LetsChat from '../components/Home/LetsChat/LetsChat';
import Layout from "../components/Layout/Layout"
import Seo from '../components/Seo/Seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Page not found" />
    <div className="container">
      404 Page
    </div>
    <LetsChat />
  </Layout>
)


export default NotFoundPage;
